table td{font-size: 14px; border-bottom: none;line-height: 1.2; }
table th{font-size: 12px; border-bottom-color:#C6C6C6!important; color:#4A4949; line-height: 1.2; font-weight: 500; text-transform: uppercase; text-align: center;}

@include media-breakpoint-down(xl) {
	.table-responsive {margin-left: -6vw;}
	table td{font-size: 13px; padding: 10px 5px!important;}
	table th{font-size: 11px; padding: 10px 5px!important;}

 }

 @include media-breakpoint-down(lg) {
	.table-responsive {margin-left: 0;}

 }

  @include media-breakpoint-down(sm) {
	.table-responsive {margin-left: -8vw;margin-right: -8vw;}
	table td{font-size: 12px; padding: 10px 4px!important;}
	table th{font-size: 11px; padding: 10px 4px!important;}

	table tr td:first-child {padding-left: 8vw!important;}

 }

