

/* Font family */
$font-primary: 'Epilogue', sans-serif;
$font-secondary: 'Space Mono', monospace;


/* Colors */
$color-blue: #0E79B2 !default;
$color-yellow: #FFAD05 !default;
$color-dark-blue: #1C212F !default;
$color-green:#C4D82E !default;
$color-light-green: #D9F7F4 !default;

/* Font-weight */
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
