//.blur {
//	-webkit-filter: blur(5px);
//	filter: blur(5px);
//	transition: filter 0.4s, -webkit-filter 0.4s, transform 0.4s;
//    transform: scale(1.05);
//}
//
//.blur.lazyloaded {
//	-webkit-filter: blur(0);
//	filter: blur(0);
//	transform: scale(1);
//}


.blur {
	opacity: 0.5;
	transition: opacity 0.4s,;
}

.blur.lazyloaded {
	opacity: 1;
}