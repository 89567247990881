.tabs {
  display:block;
 
}

/*--
  Tabs Nav
--*/
.tabs-nav  { white-space: nowrap;}
.tabs-nav > ul {
  width: calc(100% + 11vw);
  margin:0;
  padding:0;
  list-style:none;
  display:flex;
  //justify-content: space-between;
   border-bottom: 1px solid #DCD9D9
}



.tabs-nav > ul li {
  font-family: $font-secondary;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  float: left;
  padding:12px 0;
  margin: 0 35px 0 0;
  position: relative;
  transition: 0.3s ease-in-out;
  letter-spacing: -0.5px
}

.tabs-nav > ul li:last-child {margin: 0;}

.tabs-nav > ul li:before {
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 0;
  background-color: #C4D82E;
  bottom: -2px;
  left: 0;
  right: 0;
}

.tabs-nav > ul li.is-active {
  position: relative;
  color: #000000;
  z-index: 1;
    font-weight: 700;
}

.tabs-nav > ul li:hover {
  
}

.tabs-nav > ul li.is-active:before, .tabs-nav > ul li:hover:before {
  width: 100%;
}



/*--
  Tabs Content
--*/
.tabs-content > div {
  display:none;
  margin: 20px 0;
}
.tabs-content > div.is-active {
  display:block;
}


/* 2nd Level tabs yo */
.tabs .tabs > div > ul {display: block;border-bottom: none;}
.tabs .tabs > div > ul > li {
  display: block;
  border-bottom: 1px solid #DCD9D9;
  margin: 0;
  width: 100%;
  padding:12px 0;
  font-size: 15px
}
.tabs .tabs > div  > ul  > li:after {
  display: none;
}

.tabs .tabs .tabs-nav {flex:0 0 120px;margin-right: 30px;}
.tabs .tabs .tabs-content {flex:1;}

.tabs .tabs .tabs-content > div {
  margin: 0;
}

.tabs .tabs .list-1 li span { border-color: #DCD9D9; padding: 14px 0;    font-size: 15px;}



@media only screen and (max-width: 1200px) {
  .tabs-nav > ul li {
    font-size: 13px;
    margin: 0 20px 0 0;
    letter-spacing: -1px
  }
}

@media only screen and (max-width: 1023px) {
  .tabs-nav > ul { 
    //width: calc(100% + 16vw);
    //margin-left: -8vw;
    //padding-left: 8vw;
    overflow-x: auto;
  }
}

  .tabs-content {min-height: 360px}
@include media-breakpoint-up(lg) { 
}

