/* barba out current container */
#cover {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    will-change: opacity; 
}


#cover.go-dark {opacity: 1;transition: opacity 0.4s ease-out;}

#cover div:first-child, #cover div:last-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#cover div:first-child{
    background: $color-dark-blue;
}

#cover div:last-child {
    background: #fff;
}




.fade-leave {
	opacity: 1
}
.fade-leave-active {
	transition: opacity 0.4s ease-out;
}
.fade-leave-to {
	opacity: 0;
}

.fade-enter {
	opacity: 0
}
.fade-enter-active {
	transition: opacity 0s 0s;
}
.fade-enter-to {
	opacity: 1
}