
.accordion {
  margin: 0 auto;
  padding: 0;

}
.accordion.accordion-faq.accordion {
  border-top: none;
}

.accordion > li {
  
  counter-increment: item;
  position: relative;
      counter-increment: item;
}
.accordion > li > a {
  
  border-bottom: 1px solid $color-green;
}

.accordion.accordion-faq > li {
  display: block
}

.accordion .accordion-title {transition:all 0.4s;padding-left: 80px; position: relative;width: 100%;}
.accordion .accordion-title:before {
    content: counter(item, decimal-leading-zero) ". ";
    font-family: "Space Mono",monospace;
    font-size: calc(0.9rem + 0.5vw);
    color: #C4D82E;
    position: absolute;
    left: 0;
    bottom: 0.5vw;
}

.accordion li.active .accordion-title, 
.accordion li:hover .accordion-title {color: $color-green!important}

.accordion p {margin-bottom: 0;padding-bottom: 2rem;}
/*
.accordion li > a:hover {padding-left: 4px;}
*/

.accordion > li > a {
  display: flex;
  cursor: pointer;
}
.accordion.accordion-faq a {
  width: 100%;
}

.accordion-item {
  display: none;
  width: 100%;
  padding:20px 0 0 80px;
}

.accordion.accordion-faq .accordion-item {
  width: 100%;
  padding:0px 0;
}



.accordion h3:after {
  width: 67px;
  position: absolute;
  right: 0;
  content: " ";
  top: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E %3Cg id='icon-opener' transform='translate(-835 -5984)'%3E %3Crect id='Rectangle_319' data-name='Rectangle 319' width='2' height='16' transform='translate(842 5984)' fill='%23C4D82E'/%3E %3Crect id='Rectangle_320' data-name='Rectangle 320' width='2' height='16' transform='translate(851 5991) rotate(90)' fill='%23C4D82E'/%3E %3C/g%3E %3C/svg%3E");
  background-size: 15px 15px;
  background-position: center right;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.accordion a:hover h3:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E %3Cg id='icon-opener' transform='translate(-835 -5984)'%3E %3Crect id='Rectangle_319' data-name='Rectangle 319' width='2' height='16' transform='translate(842 5984)' fill='%23fff'/%3E %3Crect id='Rectangle_320' data-name='Rectangle 320' width='2' height='16' transform='translate(851 5991) rotate(90)' fill='%23fff'/%3E %3C/g%3E %3C/svg%3E");

}

.accordion > li.active  h3:after, .accordion > li.active  h3:hover:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E %3Cg id='icon-opener' transform='translate(-835 -5984)'%3E %3Crect id='Rectangle_319' data-name='Rectangle 319' width='2' height='16' transform='translate(842 5984)' fill='%23000'/%3E %3Crect id='Rectangle_320' data-name='Rectangle 320' width='2' height='16' transform='translate(851 5991) rotate(90)' fill='%23C4D82E'/%3E %3C/g%3E %3C/svg%3E");
}

.accordion > li .acc-container {
    padding: 0;
    margin: 60px 0;
    list-style: none;
}


@media only screen and (max-width: 1023px) {


}

@media only screen and (max-width: 600px) {
.accordion .accordion-title {padding-left: 60px;}

.accordion-item { padding:20px 0 0 60px;}


}