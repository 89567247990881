.text-blue, .color-blue {color:$color-blue!important }
.text-yellow, .color-yellow {color:$color-yellow!important }
.text-green, .color-green {color:$color-green!important }
.text-white, .color-white {color:#fff!important }
.text-off-white, .color-off-white {color:rgba(255,255,255,0.8)!important }


.bg-light-green {background-color:$color-light-green!important }
.bg-green {background-color:$color-green!important }
.bg-blue {background-color:$color-blue!important }
.bg-dark-blue {background-color:$color-dark-blue!important }
.bg-light-grey {background-color:#F7F4F4!important }


.bg-dark {background-color:#000!important }
.bg-dark-before {position: relative;}
.bg-dark-before:before {content: ''; position: absolute;top: -1px;left: 0;right: 0;width: 100%;height: 3px;background-color: #000;z-index: 99}

.border-blue {border-color: $color-blue!important}

