
body {
  color: #030303;
  font-weight: 400;
  overflow-x: hidden;
  font-family: $font-primary;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    transition:background-color 0.4s;
      overflow-y:auto!important; 
	-webkit-text-rendering: optimizeSpeed;
	text-rendering: optimizeSpeed;
}

img {max-width: 100%;}
a {}
hr { margin: 1rem 0; background-color: $color-green; opacity: 1; border: none }

.button { border: none; margin: 0; padding: 0; width: auto; overflow: visible; background: transparent;    /* inherit font & color from ancestor */ color: inherit; font: inherit;    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */ line-height: normal;    /* Corrects font smoothing for webkit */ -webkit-font-smoothing: inherit; -moz-osx-font-smoothing: inherit;    /* Corrects inability to style clickable `input` types in iOS */ -webkit-appearance: none; text-align: inherit; outline: none; cursor: pointer; }
.button::-moz-focus-inner { border: 0; padding: 0; }
.button:focus {outline: none;}
.button:disabled { opacity: 0.5;cursor: default;}
.button svg circle {transition:all 0.4s}
.button:hover svg circle {fill:$color-blue}
.button.btn-left {margin-right: 4px;}
.button.btn-right {margin-left: 4px;}


.flex-shrink-210 {flex:0 0 36%}
.list-unstyled {padding: 0;margin: 0;list-style: none;}
.link-inherit {color: inherit;text-decoration: none;}

.pointer-event-none {pointer-events:none}

.z-1 {z-index: 1}
.z-2 {z-index: 2}
.z-3 {z-index: 3}
.z-4 {z-index: 4}
.z-5 {z-index: 5}

.mw-420 {max-width: 420px;}
.mw-500 {max-width: 500px;}
.mw-600 {max-width: 600px;}
.mw-800 {max-width: 800px;}

.mar-t-1 {margin-top: 1px;}

.margin-bottom-min-70 {margin-bottom: -70px;}

.lh-small {line-height: 1.4; }

.img-absolute { position: absolute; left: 0; right: 0; bottom: 0; top: 0; height: 100%; object-fit: cover; background-size: cover; background-position: center; background-repeat: no-repeat; }
.bg-cover-1 { position: absolute; left: 0; right: 0; bottom: 0; background-size: contain; background-position: bottom; background-repeat: no-repeat; }


.img-ratio {width: 100%;position: relative; overflow: hidden;z-index: 2}
.img-ratio img,  .img-ratio video {position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;-o-object-fit: cover; object-position: 50% 50%;height: 100%;}

.img-ratio-w {width: 100%;position: relative; overflow: hidden;z-index: 2}
.img-ratio-w img,  .img-ratio-w video {position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: contain;-o-object-fit: contain; object-position: 50% 100%;width: 100%;}


.image-height-1 {padding-bottom: 100%;}
.image-height-2 {padding-bottom: 115%;}
.image-height-3 {padding-bottom: 71.43%;}
.image-height-4 {padding-bottom: 107%;}
.image-height-5 {padding-bottom: 56%;}

@media only screen and (min-width:1300px) {
  .min-vh-100-desk {min-height: 100vh }
  .min-vh-80-desk {min-height: 80vh }
  .min-vh-60-desk {min-height: 60vh }
}


.link-white-small {color: #fff; font-size: 14px; transition:all 0.4s}

.icon-link-1 svg path{transition:all 0.4s}
.icon-link-1:hover svg path{fill:#16F4D0}

/* Home */
.header-home{min-height: calc(100vh - 8vw);margin-top: -1px;}

.hero-image { height: calc(100vh - 19vw); width: calc(100% + 11vw)!important; display: flex;min-height: 380px;}

.hero-shape {position: absolute;left: 0;top: 0;bottom: 0;z-index: 2}
.hero-shape svg {height: 100%;}

.hero-shape-2 {position: absolute;right: -11vw;top: 0;bottom: 0;z-index: 2}
.hero-shape-2 svg {height: 100%;}

.header-logos {min-height: 11vw;background-color: #fff;margin-top: -1px;position: relative;z-index: 2;padding: 30px 0;}


.anchor-link {position: relative;top: -89px;}


.logo-list {display: flex;flex-wrap: wrap;align-items: center; list-style: none; padding: 0; perspective:500px;margin-bottom: 0;}
.logo-list li {width: calc(20% - 4vw);margin:10px 2vw;text-align: center;}
@include media-breakpoint-down(md) { 
  .logo-list { justify-content:center}
  .logo-list li {width: calc(33.3333% - 4vw);}
}

@media only screen and (max-width:560px) {
  .logo-list li{width: calc(50% - 40px);    margin: 20px 20px;}
}

@media only screen and (max-width:600px) {
  //.logo-list { justify-content: flex-start; }
  //.logo-list li {text-align: left;width: calc(50% - 2vw);}
}



.benefits-list {display: flex;flex-wrap: wrap;align-items: center; list-style: none; padding: 0; margin: 0 -10px; perspective:500px}
.benefits-list li {width: calc(25% - 20px);padding-bottom: calc(25% - 20px);margin:10px 10px;position: relative; background-color: #fff; box-shadow: 0 6px 10px rgba(0,0,0,0.02); transform-style: preserve-3d;text-align: center;}
.benefits-list li .ico-item {
    max-width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    visibility: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ico-holder { min-height: 60px }
.ico-heading { font-size:13px; font-weight: 600 }




@include media-breakpoint-down(md) {
  .benefits-list {margin: 0 -5px;}
  .benefits-list li {width: calc(33.33333% - 10px);padding-bottom: calc(33.33333% - 10px); margin:5px 5px;;}
} 

@media only screen and (max-width:440px) {
  .benefits-list li {width: calc(50% - 10px);padding-bottom: calc(50% - 10px);}

}



.list-1 {list-style: none; padding: 0;margin: 0;}
.list-1 li {padding-left: 36px; position: relative; font-weight: 500; font-size: 15px; color: #3D3935}
.list-1 li em {
    position: absolute;
    left: 0;
    top: -1px;
    width: 36px;
    display: flex;
    align-items: center;
    height: 100%;
}

.list-1 li em img, .list-1 li em svg {max-width: 35px}

.list-1 span { display: block; padding: 13px 0; line-height: 1.5}
@media only screen and (max-width:768px) {
  .list-1 li:first-child span {padding-top: 0;}
}



.scroll-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scroll-icon {
    display: block;
    position: relative;
    height: 42px;
    width: 29px;
    border: 2px solid #acb17d;
    border-radius: 1em;
}
.scroll-icon__dot {
    display: block;
    position: absolute;
    left: 50%;
    background: #030303;
    height: 6px;
    width: 6px;
    top: .6em;
    margin-left: -3px;
    border-radius: 50%;
    transform-origin: top center;
    backface-visibility: hidden;
    animation: scroll_2 2s ease-out infinite;
}

@keyframes scroll_2 {
  0% {
    transform: translateY(0);
    opacity: 1
  }

  25% {
    opacity: 1
  }

  75% {
    transform: translateY(0.75em);
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 0
  }
}

#anim-line-1 svg #p-arrow-1,
#anim-line-2 svg #p-arrow-2,
#anim-line-3 svg #p-arrow-3 { opacity: 0 }

#pin-block-1 .position-relative {}

.anim-line {position: absolute;left: 74%; top: 25%; transform: translateY(-50%);width: 10vw;}
.anim-line svg {width: 100%;}

.flip-it {transform: rotateX(180deg);}
.icon-tick {box-shadow:0 3px 6px rgba(0,0,0,0.16); border-radius: 100%}


.anim-bg-1 {width: 90%}
.position-phone {position: absolute;right: 3%;top: -9%;width: 89%;}
.position-phone-2 {position: absolute;right: 3;top: -9%;width: 95%;}



@include media-breakpoint-down(lg) {
    .anim-line { position: static;height: 220px;width: 100%; display: flex;align-items: center;justify-content: center;transform: rotateX(0);}
    .flip-it {transform: rotateY(180deg);}
    .anim-line svg{transform: rotate(90deg);  transform-origin: 50% 50%  }

    .scroll-wrapper {border-bottom: 2px dashed #dedddf;}
    .scroll-wrapper .scroll-icon {display:none }
    .scroll-wrapper .scroll-icon-dot {display:none }

    #anim-line-1 svg #p-arrow-1,
    #anim-line-2 svg #p-arrow-2,
    #anim-line-3 svg #p-arrow-3 { opacity:1 }
 }






/* Anim 2*/
.position-phone-3 {position: absolute;right: 1vw;top: -7%;width: 100%;}

.anim-phone-0 svg {width: 90%;}

.anim-bg-2{width: 90%}
.anim-bg-2 img, .anim-bg-2 svg {width: 90%}

.anim-logos-2{
    position: absolute;
    top: -22%;
    left: -3%;
    width: 96%;
}

.anim-logos-2 img, .anim-logos-2 svg{
    width: 65%;
}

.anim-logos-3 {
    position: absolute;
    top: 7%;
    left:8%;
    width: 100%;
}

.anim-logos-3 img, .anim-logos-3 svg{
    width: 80%;
}


.anim-bg-3 {}
.anim-bg-3 img{width: 90%;}


@include media-breakpoint-down(lg) { 
  [data-scroll-sticky] {transform: none!important;}
  .il-holder {
      max-width: 100%;
      position: relative;
      z-index: 0;
      margin-left: auto;
      margin: 60px 0 30px 0;
      padding-bottom: 15%;
      padding: 30px 0 10vw 0;
  }
  #il-holder-1 {
    margin-top: 40px;
    padding-bottom: 10vw;
  }
  #il-holder-2 {
    margin-top: 10vw;
    padding-bottom: 8.5vw;
  }
  #il-holder-3 {
    margin-top: 0vw;
    padding-bottom: 1vw;
  }
}


/* Steps */
.row-step {}
.col-step {position: relative;overflow: hidden;}

.step-line {position: absolute;top: 8px;right: 0;}
.step-number {position: relative;z-index: 2}

.col-step:after {content: ''; position: absolute;left: 0;top: 0;bottom: 0;width: 30px; height: 50px; background: #F7F4F4;z-index: 0}

@include media-breakpoint-down(lg) { 
.step-line {
    transform: rotate(90deg);
    transform-origin: right;
    top: 170px;
    right: calc(100% - 53px);
}


  .col-step { position: relative;min-height: 200px}
  .step-number {position: absolute;top: 0;}

  .col-step .list-1, .col-step .h6, .col-step p {padding-left: 70px;}
}


.shape-right { position: absolute;right: 0;max-width: 50vw}
.shape-right img {display: block;transform: translateY(-20%);}

.shape-bottom { position: absolute;left: 0; bottom: 0; max-width: 50vw; z-index: 5}
.shape-bottom img {display: block;transform: translateY(20%);}


.cta-left {width: 33.3333%;}
.cta-left .cta-left-image {padding-bottom: 100%;}
@include media-breakpoint-down(md) { 
  .cta-left {width: 100%;}
  .cta-left .cta-left-image {padding-bottom: 50%;}
}

/* Footer*/
footer {background-color:#000; color: #fff;}

.footer-menu {list-style: none;padding: 0;margin: 0 -15px;}
.footer-menu li {display: inline-block;margin: 0 15px;}
.footer-menu li a  {line-height: 1.5; font-size: 14px; margin-bottom: 20px; font-weight: 500; text-decoration: none; color: #808080; transition:all 0.4s;}
.footer-menu li a:hover {color: #fff;}
.footer-menu li a svg {width: 0; transition:all 0.4s}
.footer-menu li a:hover svg {width: 40px;padding-left: 20px;}


footer small{display: block; line-height: 1.5; font-size: 14px; margin-bottom: 20px; font-weight: 300; color: #808080}
footer small a{color: inherit;text-decoration: none; transition: color 0.4s}
footer small a:hover{color: #fff}
small.muted {color: #C4C0C0}
footer small em {font-style: normal; color: rgba(255,255,255,0.5)}

.footer-social {list-style: none;padding: 0;margin: 0 -2px;}
.footer-social li {margin: 0 2px; display: inline-block;}

.footer-social li a svg rect {fill:#000; transition:all 0.4s;}
.footer-social li a:hover svg rect {fill:#C4D82E; transition:all 0.4s;}

.footer-social li a svg path {fill:#C4D82E; transition:all 0.4s;}
.footer-social li a:hover svg path {fill:#000; transition:all 0.4s;}


@include media-breakpoint-down(md) {
  .mw-sm-400 {max-width: 400px}

}







.no-touch #cursor { position: fixed; transform: translate(-50%, -50%); width: 80px; height: 80px; border-radius: 50%; pointer-events: none; opacity: 1; box-sizing: border-box; transform-origin: center; opacity: 0; transition: opacity 0.3s, width 0.3s, height 0.3s; z-index: 9999 }
.no-touch #cursor.cursor-drag-active {opacity: 1;width: 110px;height: 110px;}
.touch #cursor {display: none;}





@media only screen and (max-width:1300px) {
	
}

@media only screen and (max-width:1160px) {
	

}

@media only screen and (max-width:1023px) {
	

}

@media only screen and (max-width:767px) {
   
}

@media only screen and (max-width:991px) {
    .mobile-outdent {
        margin-left: -8vw;
        margin-right: -8vw;
    }

}


@media only screen and (max-width:500px) {
    
}


@media only screen and (max-width:340px) {

}