/* HIDE RADIO */
[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
  outline: 2px solid #fff;
  transition:all 0.4s
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid $color-green;
}


.imgradio:hover  .radio-label {opacity: 0.5;}
.imgradio .radio-label {transition:all 0.4s}
.imgradio .radio-label:hover {opacity:1!important;}

.imgradio .radio-label.radio-not-selected {opacity: 0.4;filter: grayscale(1);}
.imgradio .radio-label.radio-selected {opacity:1;transform: scale(1.05);}



textarea.form-control {height: 160px!important;}
[type="submit"] {
    -webkit-appearance: none;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 4px 4px $color-green;
    background-color: #3d3935;
    transition:background-color 0.4s
}

[type="submit"]:hover, 
[type="submit"]:focus {
    background-color: #000;
    color: #fff;
    outline: none;
}












.float-label {
  position: relative;
}

.float-label label {
  cursor: auto;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  padding: 1.1rem 20px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.7;
  font-size: 15px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0);
  transition: opacity 0.1s ease-in-out,transform 0.1s ease-in-out;
  color: #676769;
  pointer-events: none;
  transform-origin: 0 0;
}

.float-label label span {color: #aaa}


.float-label label.active {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.3rem) translateX(0.15rem);
}






.float-label input[type="text"], 
.float-label input[type="email"], 
.float-label input[type="date"], 
.float-label input[type="password"], 
.float-label input[type="tel"],
.float-label input[type="search"],
.float-label select {
    height: calc(3.5rem + 2px);
    padding: 1.625rem 20px 0.5rem 20px;
    font-size: 15px!important;
    font-weight: 500;
    background-color: transparent;
    border-color: #D7D7D7;
    color: #333!important;
    min-width: 140px;
    transition: border 0.3s;
    width: 100%;
}

textarea.form-control {
    padding: 26px 20px;
    color: #333!important;
    font-weight: 500;
    font-size: 15px!important;
    transition: border 0.3s;
}



.float-label input[type="text"]:hover,
.float-label input[type="email"]:hover,
.float-label input[type="number"]:hover,
.float-label input[type="date"]:hover,
.float-label input[type="password"]:hover,
.float-label input[type="tel"]:hover,
.float-label textarea:hover,
.float-label button:hover,
.float-label select:hover {
 
}
.float-label input[type="text"]:focus,
.float-label input[type="email"]:focus,
.float-label input[type="number"]:focus,
.float-label input[type="date"]:focus,
.float-label input[type="password"]:focus,
.float-label input[type="tel"]:focus,
.float-label textarea:focus,
.float-label button:focus,
.float-label select:focus {
  outline: none;
  border-color: $color-green!important;
  box-shadow:none;
}



.float-label label.textarea-label { left: 15px; top: 13px; }
.float-label label.textarea-label.active { top: 10px; font-size: 12px; }



.float-label select {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.3s;
  z-index: auto!important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.263' height='14.251' viewBox='0 0 7.263 14.251'%3E %3Cg id='ico-down' transform='translate(-474.609 -1691.5)'%3E %3Cpath id='Path_556' data-name='Path 556' d='M0%2C0V13.544' transform='translate(478.241 1691.5)' fill='none' stroke='%233d3935' stroke-width='1'/%3E %3Cpath id='Path_90' data-name='Path 90' d='M372.3%2C27.169l3.278%2C3.278L372.3%2C33.726' transform='translate(508.688 1329.464) rotate(90)' fill='none' stroke='%233d3935' stroke-width='1'/%3E %3C/g%3E %3C/svg%3E ");

  background-repeat:no-repeat;
  background-position:  center right;
  background-size:8px;
  background-position:center right 20px;
  margin-bottom: 0;
}





/** CF7 overwrites **/

span.wpcf7-list-item {margin: 2px 10px 0 0;}


.required-fields { float: right; color: #313843; font-size: 16px; padding-top: 26px; padding-right: 30px; }
.wpcf7-form { margin-bottom: 0; }
.required { display: inline !important; }
.wpcf7-form-control-wrap { position: relative; display: block; }

span.wpcf7-not-valid-tip {
    color: #d25b5b !important;
    font-size: 12px !important;
    display: block !important;
    position: absolute!important;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
    height: 1px;
    bottom: 0px !important;
    background: #d25b5b !important;
    text-indent: -9999px !important;
}

.form-group-select span.wpcf7-not-valid-tip {
    bottom: 11px !important;
}

div.wpcf7-response-output {
    height: 0;
    text-align: left;
    margin: 0 0 0 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: 14px !important;
    clear: both;
    border-radius: 0;
    position: relative;
    bottom: -10px;
}
.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {color: #d25b5b}
.contact-form div.wpcf7-response-output {}

div.wpcf7-validation-errors { color: #d25b5b !important; }
div.wpcf7-mail-sent-ok { color: #398f14 !important; }


/* Loader */
.ajax-loader, .ajax-loader:after { border-radius: 50%; width: 20px; height: 20px; }
.ajax-loader {
    display: inline;
    position: absolute;
    z-index: 4;
    top: 50%;
    margin-top: -9px !important;
    right: -30px;
    background-image: none !important;
    font-size: 10px;

    text-indent: -9999em !important;
    border-top: 2px solid transparent !important;
    border-right: 2px solid $color-green !important;
    border-bottom: 2px solid $color-green !important;
    border-left: 2px solid $color-green !important;
    transform: translateZ(0) !important;
    animation: loading 1.1s infinite linear !important;
}
@-webkit-keyframes loading {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes loading {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $color-green;
  background-color: transparent;
  -webkit-text-fill-color: #030303;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}



@media (max-width: 960px) { 
  

}







