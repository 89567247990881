.scrub-parallax {width: 100%;position: relative; overflow: hidden;z-index: 2}
.scrub-parallax img, .scrub-parallax video {position: absolute;top: 0;left: 0;width: 100%;object-fit: cover;-o-object-fit: cover;object-position: 50% 50%;height: 120%;top: -20%;}



.scrub-parallax-2 {width: 100%;position: relative; overflow: hidden;z-index: 2}
.scrub-parallax-2 img, .scrub-parallax-2 video {position: absolute;top: 0;left: 0;object-fit: cover;-o-object-fit: cover;object-position: 100% 50%;height: 100%;top: 0;}


.zoom-in img {opacity: 0}


.blur {
-webkit-filter: blur(5px);
filter: blur(5px);
transition: filter 0.4s, -webkit-filter 0.4s;
image-rendering: pixelated;
}

.blur.lazyloaded {
-webkit-filter: blur(0);
filter: blur(0);
    image-rendering: inherit;
}
