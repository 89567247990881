
.split-holder {width: 100%;   -webkit-text-rendering: optimizeSpeed;text-rendering: optimizeSpeed;-webkit-transform: translateZ(0);}
.split-parent{ overflow: hidden;}
.split-text {perspective: 700px;}


.split-holder-2 {width: 100%;   -webkit-text-rendering: optimizeSpeed;text-rendering: optimizeSpeed;-webkit-transform: translateZ(0);}
.split-parent-2{ overflow: hidden;}
.split-text-2 {perspective: 700px;}


