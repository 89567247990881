
//     transform: translate(0%, 120%) translate3d(0px, 50px, -50px) rotateX(-90deg) skew(0deg, 10deg);


.btn { color: #fff; font-family: $font-secondary;font-size: 16px; line-height: 1; letter-spacing: 1px;padding: 10px 8px 10px 0; text-decoration: none; display: inline-block; position: relative;transition:all 0.3s ease-in}
.btn:after {content: ''; display: inline-block; right: 0;position: absolute;width: 100%;bottom: 3px;top: 3px;background-color: #2C2C2B; transition: all 0.3s}
.btn span {display: inline-block;margin-right: 10px;z-index: 2; position: relative;}
.btn svg {position: relative;z-index: 1;top: -2px;}
.btn svg path {stroke:$color-green}

.btn:hover:after  {width: 26px;}
.btn:hover {color: $color-green; }


.btn-min-half {transform: translateY(-50%);}
.btn-wide {padding: 15px 60px;}


.btn-underline {white-space: nowrap; transition:color 0.4s; padding: 15px 0px 15px 30px;display: flex;justify-content:space-between; align-items:center;color: #2A2A86; text-decoration: none; text-transform: uppercase; font-size: 15px; font-weight: 500; position: relative;}
.btn-underline:hover {color: #fff}
.btn-underline:after {content:'';display: block;position: absolute;bottom: 0;left: 0;right: 0;height: 5px; background-color: #2A2A86; transition:all 0.4s }
.btn-underline:hover:after {height: 100%; }

.btn-underline span, .btn-underline svg  {position: relative;z-index: 1;}
.btn-underline svg path {transition:all 0.4s}
.btn-underline svg {transition:all 0.4s}

.btn-underline:hover svg {transform:translateX(-30px)}
.btn-underline:hover svg path{ stroke:#fff}


.btn-arrow {white-space: nowrap; transition:color 0.4s;display: inline-block;color: #272D3D; text-decoration: none;font-size: 18px; font-weight: 800; position: relative; transition:all 0.4s}
.btn-arrow:hover {color: #272D3D}
.btn-arrow span {padding-right: 10px;}

.btn-arrow span, .btn-arrow svg  {position: relative;z-index: 1;}
.btn-arrow svg path {transition:all 0.4s}
.btn-arrow svg {transition:all 0.4s}

.btn-arrow:hover svg {transform:translateX(6px)}
.btn-arrow:hover svg path {stroke:#FFAD05 }

.bg-dark-blue .btn-arrow {color: #fff}




.btn-3 {display: inline-block; position: relative; background: #fff; font-family: $font-secondary; font-size: 16px; line-height: 1.3; text-decoration: none; color: #0F0F0F; font-weight: 700;padding: 17px 50px;box-shadow: 2px 2px black; transition:background 0.4s, color 0.4s}
.btn-3:hover { color: #0F0F0F;}
.btn-3:active {box-shadow: 0px 0px white;bottom: -2px;right: -2px;}

.btn-3 svg {position: absolute;z-index: 1;top: 50%;margin-top: -5px;right: 30px; opacity: 0; transition:all 0.3s}
.btn-3:hover svg {right: 0; opacity: 1;right: 20px;}

.btn-3-dark {background: #0F0F0F; color: #fff;box-shadow: 2px 2px #fff; }
.btn-3-dark:hover {color: #fff;}
.btn-3-dark svg path {stroke:#fff}


.btn-item-hover.btn-3 svg {right: 10px;}


.btn-3-dark-2 {background: #fff; color: #0F0F0F;box-shadow: 2px 2px $color-green; }
.btn-3-dark-2:hover {color: #0F0F0F;}



@include media-breakpoint-down(lg) {
    .btn-arrow {font-size: 16px}
}


