
//.nav-container {transition:all 0.4s;will-change: //;}

/************ NAVIGATION TRANSITION ************/
.nav-transition { position: fixed; left: 0; right: 0; z-index: 5 }

/************ NAVIGATION HOLDER ************/
    /* Spacer for section below nav */
    .nav-spacer {padding-top: 8vw; background-color: rgba(0,0,0,1);}
    .nav-spacer-2 {padding-top: 12vw;}

    /* Hide nav on down */
    .main-nav{ background-color: rgb(247,244,244); /*background-color: #fff;*/position: fixed;top: 0;left: 0;right: 0;z-index: 99; display: flex; flex-direction:column; justify-content: center; height: 8vw;transition:all 0.4s linear;will-change: transition}
    .is-loaded .main-nav { }


    .main-nav > nav {opacity: 0;}

    .main-nav .nav-inner {display: flex;justify-content: space-between;align-items: center;position: relative;margin: 0 auto;}
    @media only screen and (max-width: 1400px) {
        .main-nav .nav-inner {}
    }

    .lity-active .main-nav {opacity: 1; visibility: hidden; pointer-events: none;transform: translateY(-100%);}

    //.main-nav.nav-scroll-down {opacity: 1; visibility: hidden; pointer-events: none;transform: translateY(-100%);}
    //.main-nav.nav-scroll-up { top: 0; opacity: 1; visibility: visible; }

    .nav-moved {  }

    .nav-moved.main-nav{height: 90px}
    
    /* Reset ul's*/
    .main-nav ul{margin-bottom: 0; padding-left: 0; list-style: none;margin-left: calc(50px + 1vw);}

/************ END NAVIGATION HOLDER ************/



/************ LOGO ************/
    .main-nav .logo {}

    @media only screen and (min-width: 1450px) {
        
    }

    .main-nav .logo a {display: block;}
    .main-nav .logo path { transition:all 0.4s}
    .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {display: block; width: 113px; height: 35px; position: relative;z-index: 2 }

    body .logo #logo-group-1 path {fill: #3d3935;}
/************ END LOGO ************/



/************ MEGA MENU ************/
/* Main menu */
    .megamenu { flex: 1; margin-right: 0px;}
    .megamenu > ul {display: flex;/*justify-content: flex-end;*/position: relative;}

/* Top level text */
    .megamenu > ul > li { padding: 0 40px 0 0; text-decoration: none; transition: all .3s ease;  display: flex; align-items: center;    counter-increment: item; position: relative;}
    .megamenu > ul > li:last-child {padding-right: 0;}
    .megamenu > ul > li.current-menu-item a:after, .megamenu > ul > li a:hover:after { color: #FFAD05}
    .megamenu > ul > li > a {font-family: $font-secondary; transition:color 0.4s; color: #1C212F; position: relative;  font-size: 16px; line-height: 1; letter-spacing: 1px; text-decoration: none;padding: 10px 0;white-space: nowrap; position: relative;}
    .megamenu > ul > li.current-menu-item > a{ }

/* Top level Hover line */
    .megamenu > ul > li > a:after { content: ''; display: block; position: absolute; bottom: 4px; left: 0; width: 100%; height:1px; background-color: $color-green; transition: transform 0.3s; margin: 0 auto; transform: scaleX(0); transform-origin: left; will-change: transform, opacity}
    .megamenu > ul > li.current-menu-item  > a:after {width: 100%;left: 0; right:auto; transform: scaleX(1);}
    .megamenu > ul > li > a:hover:after { transform: scaleX(1); opacity: 1}

    .megamenu > ul > li.current-menu-item-hover > a:after {transform-origin: right; }

/* Nav CTA */
    .btn-2 { color: #1C212F; font-family: $font-secondary;font-size: 16px; line-height: 1; letter-spacing: 1px;padding: 10px 8px; text-decoration: none; display: inline-block; position: relative;transition:all 0.3s ease-in}
    .btn-2:after {content: ''; display: inline-block; right: 0;position: absolute;width: 26px;bottom: 3px;top: 3px;background-color: $color-green; transition: all 0.3s}
    .btn-2 span {display: inline-block;margin-right: 10px;z-index: 2; position: relative;}
    .btn-2 svg {position: relative;z-index: 1;top: -2px;}
    .btn-2 svg path {stroke:#000}


    .btn-2:hover:after, .current-menu-item .btn-2:after  {width: 100%;/*bottom: 0px;top: 0px;*/}
    .btn-2:hover, .current-menu-item .btn-2 {color: #000!important; }



    @include media-breakpoint-down(md) {
        .btn-2 {font-size: 15px; letter-spacing: 0;}
    }

    @include media-breakpoint-down(sm) {
        .btn-2 {font-size: 14px;}
    }

/* Dark nav*/


body.nav-dark .main-nav {background-color: rgb(0,0,0);}
body.nav-dark .main-nav  ul  li  a {color: #fff;}
body.nav-dark .main-nav .btn-2 {color: #fff;}

body.nav-dark .main-nav .logo #logo-group-1 path {fill: #fff;}



/* Hide sub heading*/
    .holder-menu-sub > a {display: none!important;}

/* Links in menu */
    .holder-menu-sub > ul > li > a {font-size: 20px!important; }


/* Mega menu open opacity layer */
    .overlay {position: fixed;top: 0;right: 0;left: 0;bottom: 0;visibility: hidden;background-color: rgba(14,17,63,0.65);webkit-backdrop-filter: saturate(180%) blur(6px); backdrop-filter: saturate(180%) blur(6px);transition: opacity 0.2s, visibility 0s 0.2s;z-index: 5;opacity: 0;}
/************ END MEGA MENU ************/



/* Nav CTA*/
.nav-cta {/*padding-top: 22px;*/}

/* DARK menu*/




@media only screen and (min-width: 992px) {
    .nav-transparent.nav-top .megamenu > ul > li > a {color: #fff; opacity: 0.9;}
    .nav-transparent.nav-top .megamenu > ul > li > a:hover {opacity: 1;}

    .menu-open .nav-transparent.nav-top .megamenu > ul > li > a {color: #1A2C42;}

    .menu-open .main-nav { background: rgba(255, 255, 255, 1); }
}
/* END Transparent menu*/

/************ RESPONSIVE ************/
    @media only screen and (min-width: 1300px) {
        //.nav-spacer-home {min-height: calc(100vh - 84px);}
        .nav-spacer-home {min-height:100vh;}
    }

     @media only screen and (min-height: 1000px) and (max-width: 1400px)  {
        .nav-spacer {min-height: auto;}
    }

    @media only screen and (max-width: 1220px) {
        .main-nav .logo a {}
    }


    .menu-opener {display: none;}

    @media only screen and (min-width: 992px) {
        .show-nav-mobile {display: none!important}
    }

    @media only screen and (max-width: 991px) {



        .show-nav-desktop {display: none!important}
        nav {     height: auto; }
        .main-nav {height: 10vw;}
        //.main-nav .nav-inner  {height: 10vw;}
        .nav-spacer {padding-top: 10vw;}
        .nav-spacer-2 {padding-top: 15vw;}
        .nav-container {}
        .main-nav .logo a {}
        .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {}



        .main-nav.menu-opened {}

        .main-nav .logo {flex: 1;}

        .megamenu {margin-left: 0; position: fixed; top: 0; right: 0; width: 100%; pointer-events: none; background-color: #3d3935; opacity: 0; visibility: hidden; transition: opacity 0.4s, visibility 0s 0.5s; }
        .menu-opened .megamenu { opacity: 1; visibility: visible; transition: opacity 0.4s;pointer-events: auto; overflow-y: auto; -webkit-overflow-scrolling: touch; }
        .menu-opened.main-nav  .logo #logo-group-1 path {fill: #fff;} 
        .menu-opened.main-nav  .btn-2 {color: #fff;} 

        .megamenu > ul {padding: 80px 8vw 4vh 0;justify-content: flex-end; display: block;}
        .megamenu > ul > li {height: initial; align-items: flex-end; flex-direction: column;text-align: right; padding-right: 0 }
        .megamenu > ul > li > a {color: #fff; font-size: 14px}
        .megamenu > ul > li > a:hover {color: #ffad05}
 
        .megamenu > ul > li:last-child > a {/*background-color: #0e79b2; color: #fff*/}
        .is-loaded .megamenu > ul li a span {max-width: 100%;display: inline-block;}


        .megamenu > ul > li.current-menu-item a:after, 
        .megamenu > ul > li a:hover:after { color: #FFAD05}
        .megamenu > ul > li.current-menu-item > a{ }
        

        .menu-image-title:before, .menu-image-title:after {display:none}
        .menu-image-title.menu-image-title-above, .menu-image-title.menu-image-title-below {padding:0;}

        /* Mobile search */
        .nav-search {margin: 30px;}
        .nav-search form {width: 100%}
        .search-flex-mobile {display: flex;}
        .search-flex-mobile input[type="search"]{flex:1; height: 64px;line-height: 64px; font-size: 19px;padding: 20px; background-color: #F7F8F9}
        .search-flex-mobile input[type="submit"] {margin-top: 0; border: 0;padding: 10px 30px;font-size: 14px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.221' height='18.221' viewBox='0 0 18.221 18.221'%3E %3Cg id='icon-search' transform='translate(-0.807 -0.807)'%3E %3Ccircle id='Ellipse_123' data-name='Ellipse 123' cx='6.5' cy='6.5' r='6.5' transform='translate(1.807 1.807)' stroke-width='2' stroke='%23F07100' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' fill='none'/%3E %3Cpath id='Path_3400' data-name='Path 3400' d='M20.684%2C20.684%2C16%2C16' transform='translate(-3.071 -3.071)' fill='none' stroke='%23F07100' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E ");background-color: transparent;background-repeat: no-repeat;background-position:center;width: 40px;}

        .search-flex-mobile input[type="search"]::-webkit-input-placeholder {text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}
        .search-flex-mobile input[type="search"]:-ms-input-placeholder {text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}
        .search-flex-mobile input[type="search"]::placeholder { text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}


        /* Burger */
        .menu-opener {
            height: 50px;
            width: 50px;
            right: -14px;
            position: relative;
            background-color: transparent;
            cursor: pointer;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .menu-opened .menu-opener {}

        .nav-transparent.nav-top .menu-opener {background-color: transparent;margin-right: 20px}

        .menu-opener span { position: relative; display: block; width: 26px; height: 2px; background-color: #000; transition: top .2s 0.2s, transform .2s, background-color 0.3s ease; transform-origin: center center; }
        .nav-dark .menu-opener span { background-color: #fff;  }

        .menu-opened .menu-opener span { transition: top .2s, transform .2s 0.2s, background-color 0.3s ease; transform-origin: center center; background-color: #fff }
        
        .menu-opener span:first-child { top: -2px; transform: rotate(0deg); }
        .menu-opener span:nth-child(2) { transition: width 0.2s, opacity 0.2s, background-color 0.2s ease; opacity: 0 }
        .menu-opener span:last-child { top: 2px; }

        .menu-opened .menu-opener span:first-child { top: 2px; transform: rotate(45deg); }
        .menu-opened .menu-opener span:nth-child(2) { opacity: 0; width: 0; }
        .menu-opened .menu-opener span:last-child { top: -2px; transform: rotate(-45deg); }

        .body-menu-opened .nav-overlay-mobile { visibility: visible; pointer-events: auto; opacity: 1; transition: opacity 0.5s; }
        .body-menu-opened {overflow:hidden!important;}
    }

    @media only screen and (max-width: 767px) {
        .main-nav .logo svg {width: 93px;height: 35px;}
        .main-nav {height: 70px;}
        //.main-nav .nav-inner  {height: 70px;}
        .nav-spacer {padding-top: 70px;}
        .nav-spacer-2 {padding-top: 120px;}
        .nav-moved.main-nav{height: 60px}
        
    }
    @media only screen and (max-width: 380px) {
        .main-nav .logo {margin: 0 0 0 0;}
        .main-nav .logo svg {width: 80px;height: 32px}
        
    }
    @media only screen and (max-width: 340px) {
        
    }



/************ END RESPONSIVE ************/