
body {
  font-size: 18px;
  line-height: 1.7;
}


.font-primary {font-family: $font-primary!important}
.font-secondary {font-family: $font-secondary!important}

.weight-light, .font-weight-light {font-weight:  $light!important}
.weight-regular, .font-weight-regular {font-weight:  $regular!important}
.weight-medium, .font-weight-medium {font-weight: $medium!important}
.weight-semibold, .font-weight-semibold {font-weight: $semibold!important}
.weight-bold, .font-weight-bold {font-weight: $bold!important}

p, .p {@include margin-bottom(2rem); font-size: 18px; line-height: 1.7; font-weight: 400}

.small {font-size: calc(13px + 0.1vw);    line-height: 1.5;}

b, strong {
    font-weight: 700;
}

/* Headings */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6  {
	@include margin-bottom(2rem);
  font-weight: 900;
}

h1, .h1 {
  font-size: calc(2rem + 1.2vw);
  line-height: 1.2;
  font-weight: 400
}


h2, .h2 {
     font-size: 3.6vw;
    line-height: 1.1
}

h3, .h3 {
     font-size: 3vw;
    line-height: 1.2
}

h4, .h4 {
    font-size: 2.2vw;
    line-height: 1.2;
    color: #3D3935;
    font-weight: 600;
}

h5, .h5 {
    font-size: 1.8vw;
    line-height: 1.4
}

h6, .h6 {
    font-size: 1.3vw;
    @include margin-bottom(1rem);
    line-height: 1.3;
    font-weight: 400;
}




.heading-1 {font-size: 20px; color: #0E1B3F; font-weight: 400}
.heading-1:after {content: '';margin-top: 10px;height: 5px;width: 28px;background-color: #4EC4B8;display: block;}

.heading-after:after {content: '';margin-top: 10px;height: 5px;width: 28px;background-color: #4EC4B8;display: block;}

.lead {
  line-height: 1.5;
	font-size: clamp(22px, 1.7vw, 26px);
  margin-bottom: 20px;

}

blockquote {font-family: $font-secondary;font-size: 20px;margin-bottom: 40px;}



.heading-height {font-size: clamp(35px, 6vh, 11vh);line-height: 1.3}
.lead-height { font-size: clamp(18px, 2.5vh, 24px);}



@media (max-width: 1300px) {
  //.heading-height {font-size: clamp(36px, 5vh, 8vh);}
  .heading-height {font-size: calc(2rem + 1.2vh);}
  
  body {
    font-size: 17px;
  }
  p, .p {font-size: 17px;}
}

@media (max-width: 959px) {
  //.heading-height {font-size: clamp(36px, 5vh, 8vh);}
  .heading-height {font-size: calc(1.2rem + 1vh);}
}


@media (max-width: 1100px) {
  //.h1-height {font-size: clamp(30px, 6vw, 5vw);}
}




@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.3rem + 1.7vw);
  }
  h2,
  .h2 {
    font-size: calc(1.9rem + 0.9vw);
  }
  h3,
  .h3 {
    font-size: calc(1.5rem + 0.9vw);
  }
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.6vw);
  }

  h5,
  .h5 {
    font-size: calc(1rem + 0.6vw);
  }

   h6,
  .h6 {
    font-size: calc(0.8rem + 0.6vw);
  }

}

@include media-breakpoint-down(sm) { 
  body {
    font-size: 16px;
  }

  .lead {margin-bottom: 10px;}
}