// No scroll on no-touch devices when loader is present - Laptops and pcs ect..
.loader-cover {position: fixed;bottom: 0;top: 0;left: 0;right: 0;z-index: 9999;       visibility: visible; transition:visibility 0s 1s;}
.is-loaded .loader-cover {visibility: hidden;}

// No scroll on touch devices when loader is present
.is-loading, .is-loading body{overflow: hidden;}
.is-loading .scroll-content, .is-loading main{overflow: hidden;}
.is-loading body{}


// Home Right column comes in
.loader-in-right { opacity: 0;transition:all 0s 1.2s;}
.is-loaded .loader-in-right { opacity: 1}

// Bottom right pizza slice comes in
.loader-in-bottom { opacity: 0;transition:all 0.4s 0s;}
.is-loaded .loader-in-bottom { opacity: 1}

// Home left column comes in
.loader-in-left { transform:translateY(-60px); opacity: 0;transition:all 0.8s 0.6s;}
.is-loaded .loader-in-left {transform:translateY(0); opacity: 1}

// Home left column comes in
.loader-in { opacity: 0;}
.is-loaded .loader-in {opacity: 1}



// Scroll down arrow comes in
.overlap-arrow { opacity: 0; transition:all 0.6s 1.3s ease-in;}
.is-loaded .overlap-arrow {bottom:30px; opacity: 1;}

// Progress cursor when page is transitioning
.is-transitioning {  pointer-events: none;  cursor: progress;}

// Gsap Dev tools z-index issue
//.gs-dev-tools {z-index: 999}


// Preloader elements
.grid-loader {  position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 99; opacity: 0; pointer-events:none; visibility: hidden;}
.grid-loader ul {list-style: none;padding: 0;margin: 0; height:100vh;}.grid-loader ul li {position: absolute;transition:all 0.8s;overflow: hidden;}
.grid-loader ul li img {position: absolute;left: 0;top: 0; object-fit:cover; height: 100%;}

.grid-loader ul li:first-child    {width: 5vw;  padding-bottom: 5vw;  bottom: 0;    right: -5vw;  border-radius:0 0 100% 0;  background-color: #FDB515; }
.grid-loader ul li:nth-child(2)   {width: 20vw; padding-bottom: 20vw; bottom: 5vw;  right: 0; background-size: cover; background-position: center;}
.grid-loader ul li:nth-child(3)   {width: 14vw; padding-bottom: 14vw; bottom: 30vw; right: 6vw;  }
.grid-loader ul li:nth-child(4)   {width: 5vw;  padding-bottom: 5vw;  bottom: 25vw; right: 20vw;  border-radius:100% 0 0 0; }
.grid-loader ul li:nth-child(5)   {width: 14vw; padding-bottom: 14vw; bottom: 11vw; right: 25vw; }
.grid-loader ul li:nth-child(6)   {width: 5vw;  padding-bottom: 5vw;  bottom: 6vw;  right: 39vw;  border-radius: 0 0 0 100%;  }
.grid-loader ul li:nth-child(7)   {width: 4vw;  padding-bottom: 4vw;  bottom: 47vw; right: 35vw;  }
.grid-loader ul li:nth-child(8)   {width: 20vw; padding-bottom: 22vw; bottom: 25vw; right: 39vw;  } 
.grid-loader ul li:nth-child(9)   {width: 19vw; padding-bottom: 20vw; bottom: 5vw;  right: 59vw;  }
.grid-loader ul li:nth-child(10)  {width: 6vw;  padding-bottom: 6vw;  bottom: 25vw; right: 78vw;  border-radius: 100% 0 0 0;  }
.grid-loader ul li:nth-child(11)  {width: 10vw; padding-bottom: 10vw; bottom: 31vw; right: 68vw;  }


// Remove preloader elements
.is-loaded .grid-loader { transition:all 0.6s 1.2s;}
.is-loaded .grid-loader ul li {position: absolute;transition:all 0.6s; transform:translateY(100%);opacity: 0; transform-origin:top}
.is-loaded .grid-loader ul li img {position: absolute;transition:all 0.6s;transform:translateY(-100%); transform-origin:top;}
           
           .grid-loader ul li:nth-child(2)   {height: 20vw; transition:width 0.6s 0.6s,height 0.6s 0.6s, opacity 0s 1.2s,min-height 0.6s 0.6s;max-height: 50vw;min-height: 20vw  }
.is-loaded .grid-loader ul li:nth-child(2)   {width: 39vw; opacity: 0;height: calc(100vh - 13vw);min-height: 380px}

.is-loaded .grid-loader ul li:nth-child(2),
.is-loaded .grid-loader ul li:first-child {transform:translateX(0)}

.grid-loader ul li:nth-child(2) img,
.grid-loader ul li:first-child img { opacity: 0;position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;-o-object-fit: cover;object-position: 50% 50%;height: 110%;top: -10%;}

.is-loaded .grid-loader ul li:nth-child(2) img, 
.is-loaded .grid-loader ul li:first-child img {transform:translateX(0);opacity: 1}


@media only screen and (max-width:991px) {
	.grid-loader {display: none;}
}